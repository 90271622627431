import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

import PriceTip from "../Button/PriceTip"

import BestPriceTip from "../../images/best.png"
import AffordablePriceTip from "../../images/affordable.png"
import BudgetPriceTip from "../../images/budget.png"

import DotIcon from "../Icons/DotIcon"
import PhoneIcon from "../Icons/PhoneIcon"
import NoteIcon from "../Icons/NoteIcon"

import ItemHover from "../ItemHover"

const CardWrap = styled.div`
  position: relative;
  width: 95%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.16);
  background: white;
  padding-top: 1px;
  & :hover {
    & img {
      transition: transform ease 0.5s !important;
    }
    & .gatsby-image-wrapper {
      & picture {
        & img {
          transform: scale(1.1);
          transition-delay: none !important;
          transition: transform ease 0.5s !important;
        }
      }
    }
  }
`
const Thumbnail = styled.div`
  position: relative;
  z-index: 30;
  width: 105%;
  max-height: 365px;
  margin-left: -2.5%;
  border-radius: 10px;
  & .gatsby-image-wrapper {
    border-radius: 10px;
    max-height: 365px;
  }
`
const CardLabel = styled.div`
  margin: 20px 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #676b79;
  & span {
    margin-left: 10px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
  }
`
const ProductId = styled.div`
  position: relative;
  z-index: 30;
  margin-top: -40px;
  margin-left: -2.5%;
  padding-left: 40px;
  color: white;
  width: 105%;
  text-transform: uppercase;
  font-size: 14px;
  height: 40px;
  font-weight: bold;
  background: linear-gradient(to bottom, rgba(1, 9, 32, 0.01), #010920);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`
const TextPart = styled.div`
  padding: 32px;
  padding-bottom: 80px;
  /* display: grid;
  align-items: center;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto;
  grid-column-gap: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, auto);
    grid-row-gap: 15px;
  } */
`
const Left = styled.div`
  & span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    color: #676b79;
    line-height: 26px;
  }
  & p {
    font-family: Roboto;
    text-align: left;
    font-size: 12px;
    line-height: 20px;
    color: #676b79;
    margin-top: 6px;
  }
  & svg {
    vertical-align: middle;
  }
`

const CardBottom = styled.div`
  bottom: 0;
  position: absolute;
  border-top: 1px solid #e4e0e0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: rgba(1, 9, 32, 0.02);
  border-radius: 0px 0px 10px 10px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  & a {
    text-decoration: none;
  }
  @media (max-width: 400px) {
    font-size: 18px;
  }
  @media (max-width: 370px) {
    font-size: 16px;
  }
`
const CallButton = styled.div`
  padding: 17px 0;
  border-right: 1px solid #e4e0e0;
  color: #ed602b;
  cursor: pointer;
  transition: color 0.3s linear;
  & span {
    vertical-align: middle;
    margin-left: 10px;
    font-family: Chakra Petch;
    font-weight: 700;
  }
  & svg {
    vertical-align: middle;
    & path {
      transition: fill 0.3s linear;
    }
  }
  & :hover {
    color: #1e4156;
  }
`
const ViewDetail = styled.div`
  color: #1e4156;
  padding: 17px 0;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s linear;
  & span {
    vertical-align: middle;
    margin-left: 10px;
    font-family: Chakra Petch;
    font-weight: 700;
  }
  & svg {
    vertical-align: middle;
    & path {
      transition: fill 0.3s linear;
    }
  }
  & :hover {
    color: #ed602b;
  }
`
const ProductName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #062c44;
  line-height: 24px;
  margin-bottom: 6px;
  font-family: Chakra Petch, sans-serif;
`
// const Price = styled.p`
//   font-size: 34px !important;
//   font-weight: 700 !important;
//   line-height: 41px !important;
//   float: right;
//   @media (max-width: 768px) {
//     font-size: 30px !important;
//     float: left;
//   }
// `
class ProductCardWithData extends React.Component {
  constructor(props) {
    super(props)
    this.priceTip = this.priceTip.bind(this)
    this.availableService = this.availableService.bind(this)
  }
  availableService(data) {
    let services = []
    data.productServices.forEach(function (item) {
      if (
        item.service === "Financing Available" ||
        item.service === "Rent To Own Available"
      ) {
        services.push(item)
      }
    })
    if (services.length === 0) {
      return null
    } else if (services.length === 1) {
      return <span>{services[0].service}</span>
    } else {
      return (
        <div>
          <span>{services[0].service}</span>
          <span>
            {" "}
            <DotIcon /> {services[1].service}
          </span>
        </div>
      )
    }
  }
  priceTip(data) {
    if (data.productRoofType.name === "Vertical Roof") {
      return (
        <div>
          <PriceTip text="Best" bgImg={BestPriceTip} width="75px" /> Vertical
          Roof - Highly Recommended
        </div>
      )
    } else if (data.productRoofType.name === "A-Frame Roof") {
      return (
        <div>
          <PriceTip
            text="Affordable"
            bgImg={AffordablePriceTip}
            width="110px"
            pr="7px"
          />{" "}
          A-Frame Roof - Better Quality
        </div>
      )
    } else {
      return (
        <div>
          <PriceTip
            text="Budget"
            bgImg={BudgetPriceTip}
            width="90px"
            pr="5px"
          />{" "}
          Regular Roof - Good Quality
        </div>
      )
    }
  }
  render() {
    const { data } = this.props
    const categoryUrl = data.productCategory.name
      .split(" ")
      .join("-")
      .toLowerCase()
    const roofTypeUrl = data.productRoofType.name
      .split(" ")
      .join("-")
      .toLowerCase()
    const temp = data.title.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ")
    const titleUrl = temp
      .split(" ")
      .join("-")
      .toLowerCase()
    return (
      <CardWrap>
        <CardLabel>{this.priceTip(data)}</CardLabel>
        <Link to={`/${categoryUrl}/${roofTypeUrl}/${titleUrl}/`}>
          <Thumbnail>
            <Img fluid={data.image.fluid} alt={data.title} />
          </Thumbnail>
        </Link>
        <ProductId>PB#{data.skuNumber}</ProductId>
        <TextPart>
          <Left>
            <ProductName>{data.title}</ProductName>
            <div>{this.availableService(data)}</div>
            <p>{data.priceDescription}</p>
          </Left>
          {/* <div>
            <Price>${data.price}*</Price>
          </div> */}
        </TextPart>
        <CardBottom>
          <ItemHover>
            {hoverItem => (
              <a href="tel:8777541818">
                <CallButton>
                  <PhoneIcon fill={hoverItem ? "#1E4156" : "#ED602B"} />
                  <span>Call Now</span>
                </CallButton>
              </a>
            )}
          </ItemHover>
          <Link to={`/${categoryUrl}/${roofTypeUrl}/${titleUrl}/`}>
            <ItemHover>
              {hoverItem => (
                <ViewDetail>
                  <NoteIcon fill={hoverItem ? "#ED602B" : "#1E4156"} />
                  <span>View Details</span>
                </ViewDetail>
              )}
            </ItemHover>
          </Link>
        </CardBottom>
      </CardWrap>
    )
  }
}

export default ProductCardWithData
