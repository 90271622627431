import React from 'react'

const NoteIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23.318" height="23.996" viewBox="0 0 23.318 23.996">
    <g id="Group_2313" data-name="Group 2313" transform="translate(-643 -5488)">
      <g id="Group_2312" data-name="Group 2312">
        <g id="writing_1_" data-name="writing (1)" transform="translate(643 5488)">
          <g id="Group_46" data-name="Group 46" transform="translate(0)">
            <g id="Group_45" data-name="Group 45" transform="translate(0)">
              <path id="Path_29" data-name="Path 29" d="M114.078,200.937a.937.937,0,0,0-.937-.937H107.4a.937.937,0,0,0,0,1.875h5.744A.937.937,0,0,0,114.078,200.937Z" transform="translate(-102.715 -190.627)" fill={props.fill}/>
              <path id="Path_30" data-name="Path 30" d="M107.4,280a.937.937,0,1,0,0,1.875h1.953a.937.937,0,1,0,0-1.875Z" transform="translate(-102.715 -266.877)" fill={props.fill}/>
              <path id="Path_31" data-name="Path 31" d="M44.184,22.121H29.319a1.877,1.877,0,0,1-1.875-1.875V3.749a1.877,1.877,0,0,1,1.875-1.875h12.5A1.877,1.877,0,0,1,43.7,3.749V8.514a.937.937,0,1,0,1.875,0V3.749A3.754,3.754,0,0,0,41.821,0h-12.5A3.754,3.754,0,0,0,25.57,3.749v16.5A3.754,3.754,0,0,0,29.319,24H44.184a.937.937,0,0,0,0-1.875Z" transform="translate(-25.57)" fill={props.fill}/>
              <path id="Path_33" data-name="Path 33" d="M118.028,120H107.4a.937.937,0,0,0,0,1.875h10.632a.937.937,0,1,0,0-1.875Z" transform="translate(-102.715 -114.376)" fill={props.fill}/>
            </g>
          </g>
        </g>
      </g>
      <g id="Group_2309" data-name="Group 2309" transform="translate(12537 14729)">
        <g id="Group_2309-2" data-name="Group 2309" transform="translate(-11885 -9230)">
          <g id="Group_2309-3" data-name="Group 2309" transform="translate(0 0)">
            <path id="Path_15364" data-name="Path 15364" d="M13.911,97.024c-.125-.171-3.105-4.189-6.911-4.189S.214,96.853.089,97.024a.462.462,0,0,0,0,.545c.125.171,3.105,4.189,6.911,4.189s6.786-4.018,6.911-4.189A.461.461,0,0,0,13.911,97.024ZM7,100.835c-2.8,0-5.232-2.667-5.95-3.539.718-.873,3.141-3.538,5.95-3.538s5.231,2.666,5.95,3.539C12.233,98.169,9.809,100.835,7,100.835Z" transform="translate(0 -92.835)" fill={props.fill} stroke={props.fill} strokeWidth="0.6"/>
          </g>
        </g>
        <g id="Group_2310" data-name="Group 2310" transform="translate(-11880.333 -9227.667)">
          <g id="Group_2311" data-name="Group 2311" transform="translate(0 0)">
            <path id="Path_15365" data-name="Path 15365" d="M156.909,154.725a2.188,2.188,0,1,0,2.188,2.188A2.19,2.19,0,0,0,156.909,154.725Zm0,3.646a1.458,1.458,0,1,1,1.458-1.458A1.46,1.46,0,0,1,156.909,158.371Z" transform="translate(-154.722 -154.725)" fill={props.fill} stroke={props.fill} strokeWidth="0.6"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default NoteIcon