import React, { Component } from 'react'
import styled from 'styled-components'
import ProductCardWithData from "../ProductCardWithData"

const ProductGrid = styled.div`
	margin-top: 50px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: auto;
	grid-column-gap: 50px;
	grid-row-gap: 50px;
	@media(max-width: 767px) {
		grid-template-columns: repeat(1, 1fr);
	}
`
class ProductCardListWithData extends Component {
	render() {
		const { data } = this.props
		return (
			<div>
				<ProductGrid>
					{
						data.map((prodcut, i) => {
							return (
								<ProductCardWithData data={prodcut} order={i} key={i} />
							)

						})
					}
				</ProductGrid>
			</div>
		)
	}
}

export default ProductCardListWithData